@import url(https://use.typekit.net/lih3wbe.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: proxima-nova, sans-serif !important;
}

.maxHeight {
  height: 100vh;
}
.centerForce {
  margin: auto;
}

.AppRoot {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: lightgray;
}

.AppBody {
  display: flex;
  flex: 1 1;
  background-color: white;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.AppStoreBadge {
  height: 50px;
  padding: 2px;
}
.AdjustedSize {
  height: 52px !important;
}
.AppTitle {
  font-weight: 800;
}
.AudienceSlideShowContainer {
  padding-top: 0.25em;
  background: #e91414;
  color: white;
  border-radius: 2px;
  /*background: #eeeeee;
  border-bottom: #e91414;
  border-radius: 15px;*/
  display: inline-block !important;
  width: 180px !important;
  text-align: center;
}

.Padded {
  padding: 1em;
}
.VideoSizeAdjustment {
  height: 70%;
}
.AdjustPhoneHeight {
  height: 100vh;
}
/*screen-sm*/
@media (min-width: 0px) and (max-width: 750px) {
  .ConditionalCenteredText {
    text-align: center;
    width: 100%;
  }

  .ConditionalCenteredView {
    margin: auto;
  }
  .AdjustPhoneHeight {
    height: 100%;
  }
}
.text-transition * {
  text-align: center !important;
  margin: auto auto !important;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.9em !important;
}
.text-transition_inner * {
  width: 100%;
}
/*
.TextMobiBanner {
  background: #ea1414;
}
*/

p.TextMobiBanner {
  color: #3c3c3c;
  line-height: 1;
  font-weight: 500;
  text-rendering: optimizelegibility;
  text-align: center;
  padding: 1em;
}

